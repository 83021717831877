<template>
  <!-- <div></div> -->
  <suy-table v-model:columns="columns">
    <template #search>
      <a-row :gutter="[24,6]" justify="start">
        <!-- <a-col :span="6">
          <a-form-item label="操作人">
            <a-input v-model:value="search.name" placeholder="操作人" />
          </a-form-item>
        </a-col> -->
        <a-col :span="6">
          <a-form-item label="">
            <a-input v-model:value="search.setName" placeholder="模板名称" />
          </a-form-item>
        </a-col>
        <a-button type="primary" @click="searchData">查询</a-button>
      </a-row>
    </template>

    <a-table :columns="columns" :row-key="record => record.id" :data-source="dataSoure" :pagination="pagination" :loading="loading" @change="handleTableChange">
      <template #channelCounts="{record}">
        <div>
          <a-tag color="green" v-for="item in record.channelCounts" :key="item.count">{{conversionS(item.channel)}}</a-tag>
        </div>
      </template>
      <template #imgUrl="{record}">
        <div>
          <a-image :width="100" :src="record.imgUrl" :preview="false" @click="editNew(record)" />
        </div>
      </template>
      <template #operation="{record}">
        <div>
          <a @click="edit(record)">
            <img style="margin-top:-5px;width:14px;height:14px" src="@/assets/images/configuration.png" />
            配置
          </a>
        </div>
      </template>
    </a-table>
  </suy-table>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import SuyTable from '@/components/SuyTable'
import { getTemplateList } from '@/api/marketing/modList'
import { conversionS } from '@/utils/util'
export default {
  components: {
    SuyTable
  },
  setup () {
    const router = useRouter()
    const state = reactive({
      loading: false,
      dataSoure: [],
      columns: [
        {
          title: '模板名称',
          dataIndex: 'name'
        },
        {
          title: '投放情况',
          dataIndex: 'channelCounts',
          width: '20%',
          slots: {
            customRender: 'channelCounts'
          }
        },
        {
          title: '上传时间',
          dataIndex: 'createTime'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      options: [
        {
          value: 1,
          label: '状态1'
        },
        {
          value: 2,
          label: '状态2'
        },
        {
          value: 3,
          label: '状态3'
        }
      ],
      search: {
        name: '',
        setName: ''
      },
      pagination: {
        current: 1,
        pageSizeOptions: ['3', '5', '10'],
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    onMounted(() => {
      loadData()
    })
    const searchData = () => {
      state.pagination.current = 1
      loadData()
    }
    const loadData = () => {
      state.loading = true
      getTemplateList({
        current: state.pagination.current,
        templateName: state.search.setName
      }).then(res => {
        if (res.code === 10000) {
          state.dataSoure = res.data.records
          state.pagination.size = res.data.size
          state.pagination.total = res.data.total
        }
      }).catch(err => console.log(err)).finally(() => { state.loading = false })
    }
    // 表格数据页码改变事件
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    const editNew = record => {
      router.push({
        name: 'editNew',
        params: { id: record.id }
      })
    }
    const edit = record => {
      router.push({
        name: 'editNew',
        params: { id: record.id }
      })
    }

    return {
      ...toRefs(state),
      handleTableChange,
      searchData,
      conversionS,
      edit,
      editNew
    }
  }
}
</script>

<style lang="less" scoped>
</style>
